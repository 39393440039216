@import '../_colours';

.bg-dark
	#mainNav.navbar-dark
	.navbar-collapse
	.navbar-sidenav
	> .nav-item
	> .nav-link {
	color: $savant-silver;
}

.cu_bg {
	.content-wrapper {
		background: $earthi-white;
		background: linear-gradient(
			90deg,
			$savant-copper 0px,
			$earthi-white 10px,
			$earthi-white 100%
		);
	}
	.sticky-footer {
		background: #e9ecef;
		background: linear-gradient(
			90deg,
			$savant-copper 0px,
			#e9ecef 10px,
			#e9ecef 100%
		);
	}
}
.ni_bg {
	.content-wrapper {
		background: rgb(255, 255, 255);
		background: linear-gradient(
			90deg,
			$savant-zinc 0px,
			$earthi-white 10px,
			$earthi-white 100%
		);
	}
	.sticky-footer {
		background: #e9ecef;
		background: linear-gradient(
			90deg,
			$savant-zinc 0px,
			#e9ecef 10px,
			#e9ecef 100%
		);
	}
}
.steel_bg {
	.content-wrapper {
		background: rgb(255, 255, 255);
		background: linear-gradient(
			90deg,
			$savant-silver 0px,
			$earthi-white 10px,
			$earthi-white 100%
		);
	}
	.sticky-footer {
		background: #e9ecef;
		background: linear-gradient(
			90deg,
			$savant-silver 0px,
			#e9ecef 10px,
			#e9ecef 100%
		);
	}
}

.nav-link-text sup {
	position: absolute;
	top: 10px;
	right: 10px;

	.badge.badge-primary {
		background-color: rgba(0, 123, 255, 0.7);
	}
}

#mainNav .btn.btn-secondary {
	padding: 7px 15px;

	&.copper {
		border-color: $savant-copper;
		background-color: $savant-copper;
	}
	&.nickel {
		border-color: $savant-zinc;
		background-color: $savant-zinc;
	}
	&.steel {
		border-color: $savant-silver;
		background-color: $savant-silver;
	}
}

#navbarAccountDropdownMenuLink {
	@media only screen and (max-width: 991px) {
		margin-top: 10px;
	}
}
