@import './_colours';

// Override theme colours
$theme-colors: (
	'bg-dark': $earthi-space-grey,
	'info': $savant-copper,
	'warning': $savant-copper,
	'success': $earthi-vivid-green
);

@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/startbootstrap-sb-admin/scss/sb-admin';

@-webkit-keyframes autofill {
	to {
		color: #000;
		background-color: #fff;
	}
}

input:-webkit-autofill {
	-webkit-animation-name: autofill;
	-webkit-animation-fill-mode: both;
}

.modal {
	background-color: rgba(0, 0, 0, 0.3);
}
.modal-backdrop {
	position: relative;
}

.pointered {
	cursor: pointer;
}

.btn.disabled {
	cursor: not-allowed;
}

.navbar {
	padding-top: 0.55em;
}
.navbar-brand {
	padding-top: 0;
	padding-bottom: 0;
}
#mainNav .navbar-brand {
	width: 160px;
}

.savant-logo {
	position: absolute;
	top: 8px;
	left: 14px;
	height: 42px;
}
.savant-logo-preview {
	position: absolute;
	top: 31px;
	left: 192px;
	color: #fff;
}
.btn-badge {
	border-color: $earthi-sea-green;
	background-color: $earthi-sea-green;
	color: $earthi-white;
	font-weight: 500;
}
.padded-span {
	padding: 0.38rem !important;
}
.modal-body dd {
	margin-left: 10px;
}

.card-logo-header {
	padding: 0.25rem 0 0;
}
.card-logo {
	display: inline-block;
	vertical-align: middle;
}
.card-logo div {
	padding: 0;
	margin: 0;
	max-height: 90px;
}
.earthi-card-logo {
	height: 90px;
}
.savant-card-logo {
	height: 53px;
	padding: 12px 0 20px 15px;
	border-left: 1px solid $earthi-space-grey;
	margin-top: 1px;
}

.esa-card-logo {
	width: 110px;
	margin-top: 15px;
}
.marex-card-logo {
	width: 230px;
	margin-top: 12px;
}

#mainNav.navbar-dark
	.navbar-collapse
	.navbar-sidenav
	> .nav-item
	> .nav-link.disabled {
	pointer-events: none;
	cursor: default;
	color: #51575d;
}

.max-w {
	max-width: 1100px;
}

.popover {
	max-width: 700px;
}
.popover-body {
	padding: 0;
	margin: 3px;
}

.profilePic {
	width: 135px;
}

.container-width {
	max-width: 1100px;
	margin-left: 0;
	min-width: 870px;
}

#sidenavToggler {
	background: none;
	border: none;
	width: 250px;
}
